import { collection, deleteDoc, doc, getDocs, query, setDoc, updateDoc, where } from '@firebase/firestore'
import React, { useEffect, useState } from 'react'
import Lister from '../Helper/Lister'

import { db, storage } from '../../Firebase/config'
import Year from '../Helper/Year'
import Loader from '../Helper/Loader'
import { getDownloadURL, ref, uploadBytes } from '@firebase/storage'


function Execom() {
  const [members, setMembers] = useState([])
  const [loader,setLoader] = useState(false)
	const [addNew, setAddNew] = useState(false)
	const [edit, setEdit] = useState(false)
	const [editId, setEditId] = useState('')
	const [name, setName] = useState('')
	const [branch, setBranch] = useState('')
	const [society, setSociety] = useState('Main')
	const [eamil, setEmail] = useState('')
	const [year, setYear] = useState(new Date().getFullYear())
	const [position, setPosition] = useState('Chairperson')
	const [photo, setPhoto] = useState('')
	const [currYear, setCurrYear] = useState('')
	const positions = 
	["Counsellor", 
		"Chairperson", 
		"Vice Chairperson", 
		"Link Representative", 
		"Secretary", 
		"Joint Secretary", 
		"Treasurer", "MDC", 
		"Webmaster", "Technical Lead", 
		"Design Lead", 
		"Electronic Communication Coordinator", 
		"Activity Coordinator", 
		"Membership Development Team", 
		"RAS Technical Coordinator", 
		"RAS Chapter Advisor", 
		"RAS Chair", 
		"RAS Vice Chair", 
		"RAS Secretary", 
		"WIE Chair", 
		"WIE Vice Chair", 
		"WIE Secretary", 
		"CS Chapter Advisor", 
		"CS Chair", 
		"CS Vice Chair", 
		"CS Secretary", 
		"CS Women In Computing", 
		"Student Outreach Cordinator",  
		"Humanitarian Lead",
		"PES WIP",
		"Certification Lead",
		"Media Manager"
	]

  useEffect(() => {
		fetchMembers()
  }, [])

  const fetchMembers= async (year=new Date().getFullYear())=>{
  	const querySnapshot = await getDocs(query(collection(db, "execom"),where('Year','==',year)));
  	let members=[]
  	querySnapshot.forEach((doc) => {
  		members.push({ id: doc.id, ...doc.data() })
  	});
  	setMembers(members)
	setLoader(false)
  }
  const handleYearClick = (year,e)=>{
		
		setLoader(true)
		fetchMembers(year)
		setCurrYear(year)
  }
  const handleAddNew = ()=>{
		setName('')
		setBranch('')
		setEmail('')
		setPosition('Chairperson')
		setSociety('Main')
		setYear('2025')
		setPhoto('')
		setAddNew(!addNew)
  }
	const handleSubmit = ()=>{
		if(name===''||eamil===''||branch===''||photo===''){
			alert('All fields are mandatory')
			return
		}
		setLoader(true)
		const docData = {
			Name:name,
			Branch:branch,
			Society:society,
			Year:parseInt(year),
			IEEE_Email:eamil,
			Position:position,
			Photo:photo,

		}
		if(addNew){
			setDoc(doc(collection(db, "execom")), docData).then(()=>{setLoader(false)});
			setAddNew(false)
		}else if(edit){
			updateDoc(doc(db,'execom',editId),docData).then(()=>{setLoader(false)})
			setEdit(false)
			fetchMembers(currYear)
		}
	}
	const handleImageUpload = (e)=>{
		const img = e.target.files[0]
		uploadImage(img)
	}
	function getRandomString(length) {
    var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var result = '';
    for ( var i = 0; i < length; i++ ) {
        result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
    }
    return result;
	}
	const uploadImage = (img)=>{
		const storageRef = ref(storage, `execom/${getRandomString(10)}`);
		uploadBytes(storageRef, img).then((snapshot) => {
			getDownloadURL(snapshot.ref).then((url)=>{setPhoto(url)})
		});
	}

	const editItem = async (id,year)=>{
		const docRef = doc(db, "execom", id);
		const docSnap = await getDocs(query(collection(db, "execom"), where('__name__', '==', id)));

		if (!docSnap.empty) {
			const data = docSnap.docs[0].data();
			setName(data.Name);
			setBranch(data.Branch);
			setEmail(data.IEEE_Email);
			setPosition(data.Position);
			setSociety(data.Society);
			setYear(data.Year);
			setPhoto(data.Photo);
			setEditId(id);
			setEdit(true);
		}
	}

	const removePhoto = ()=>{
		setPhoto('')
	}

	const deleteItem = async (id,year)=>{
		setLoader(true)
		console.log(id,year)
		const q = query(collection(db,'execom'),where('Position','==',id),where('year','==',year))
		const querySnapshot = await getDocs(q);
		querySnapshot.forEach((doc) => {
			deleteDoc(doc.ref)
		})
		fetchMembers(currYear)
		setLoader(false)
	}

  return (
    <div className='box-top'>
		{!addNew && !edit && !loader?
			<React.Fragment>
				<div className="addNew">
					<button onClick={handleAddNew}>Add New</button>
				</div>
				<div className="member-det">
					<p className='row-heading'>SI </p>
					<p className='row-heading'>Name</p>
					<p className='row-heading d-sm-none'>Position</p>
					<p className='row-heading d-sm-none'>Society</p>
					<p className='row-heading'>Action</p>
				</div>
				{loader && <Loader/>}
				{members.map((member,index)=>
					<Lister key={member.id} col1={member.Name} col2={member.Position} col3={member.Society} col4={member.Year} index={index} editItem={() => editItem(member.id, member.year)} deleteItem={deleteItem} />
				)}
				{members.length===0 && <p>No members selected</p>}
				<Year handleClick={handleYearClick} curYear={currYear}/>
			</React.Fragment>
		:
			loader?
			<Loader/>
			:
			<React.Fragment>
				<div className="addNew-top">
					<div className="addNew-qstn">
						<label htmlFor="name">Name</label>
						<input type="text" className="addNew-name" id="name" onChange={(e)=>{setName(e.target.value)}} value={name} required />
					</div>
					<div className="addNew-qstn">
						<label htmlFor="branch">Branch</label>
						<input type="text" className="addNew-branch" id="branch" onChange={(e)=>{setBranch(e.target.value)}} value={branch} required />
					</div>
					<div className="addNew-qstn">
						<label htmlFor="email">Email</label>
						<input type="text" className="addNew-eamil" id="email" onChange={(e)=>{setEmail(e.target.value)}} value={eamil} required />
					</div>
					<div className="addNew-qstn">
						<label htmlFor="">Position</label>
						<select name="" id="" className="addNew-society" value={position} onChange={(e)=>{setPosition(e.target.value)}} required>
							{positions.map(pos=>
								pos==='Chaiperson'?
									<option className="slct-option" value={pos}>{pos}</option>
								:
									<option className="slct-option" value={pos}>{pos}</option>
								)}
						</select>
					</div>
					<div className="addNew-qstn">
						<label htmlFor="">Society</label>
						<select name="" id="" className="addNew-society" value={society} onChange={(e)=>{setSociety(e.target.value)}} required>
							<option  className="slct-option" value='Main' >Main</option>
							<option  className="slct-option" value='ras'>RAS</option>
							<option  className="slct-option" value='wie'>WIE</option>
							<option  className="slct-option" value='cs'>CS</option>
							<option  className="slct-option" value='cass'>CASS</option>
							<option  className="slct-option" value='pes'>PES</option>
						</select>
					</div>
					<div className="addNew-qstn">
						<label htmlFor="">Year</label>
						<select name="" id="" className="addNew-society" value={year} onChange={(e)=>{setYear(e.target.value)}} required>
							<option className="slct-option" value='2025' >2025</option>
							<option className="slct-option" value='2024' >2024</option>
							<option className="slct-option" value='2023' >2023</option>
							<option value="2022" className="slct-option">2021</option>
							<option value="2021" className="slct-option">2021</option>
							<option value="2020" className="slct-option">2020</option>
							<option value="2019" className="slct-option">2019</option>

						</select>
					</div>
					<div className="addNew-qstn">
						<label htmlFor="">Photo</label>
						{photo? <p onClick={removePhoto}>Remove Photo</p> : <input type="file" onChange={handleImageUpload} className="addNew-photo" />}
					</div>
					<div className="sub-button">
						<button onClick={()=>{
                setAddNew(false) 
                setEdit(false)
              }}>Cancel
            </button>
						<input type="submit" onClick={handleSubmit} className="sub-inpt" />
					</div>
				

				</div>
			</React.Fragment>
		}
    </div>
  )
}

export default Execom
